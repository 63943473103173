<template>
    <div id="corporate" :class="{webp: isSupportWebp}" data-view>
        <div class="corporate-content">
            <div class="corporate-content__left">
                <General :num="num" @postNum="getNum" :lastNews="lastNews"/>
                <Other />
                <News :posts="posts"/>
                <Contact />
                <Thank />
            </div>
            <div class="corporate-content__right">
                <Navigation :num="num" :page="page" @changeLocale="changeLocale" :locale="locale"/>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    const General = () => import("./components/corporate/General");
    const Navigation = () => import("./components/corporate/Navigation");
    import lazyLoadComponent from "./utils/lazy-load-component";
    import SkeletonBox from "./components/SkeletonBox";

    import {Post} from "./mixins/post"
    import 'lazysizes'

    const WebFont = require('webfontloader')

    export default {
        name: 'Corporate',
        metaInfo () {
            return {
                title: 'Corporate Site | Koikeya',
                meta: [
                    { property: 'og:title', content: 'Corporate Site | Koikeya', vmid: 'og:title' }
                ]
            }
        },
        components: {
            General,
            Navigation,
            Other: lazyLoadComponent({
                componentFactory: () => import('./components/corporate/Other'),
                loading: SkeletonBox,
                loadingData: { props: { anchor: 'other', scroll: 3, height: '100vh' } }
            }),
            News: lazyLoadComponent({
                componentFactory: () => import('./components/corporate/News'),
                loading: SkeletonBox,
                loadingData: { props: { anchor: 'news', scroll: 4, height: '100vh' } }
            }),
            Contact: lazyLoadComponent({
                componentFactory: () => import('./components/corporate/Contact'),
                loading: SkeletonBox,
                loadingData: { props: { anchor: 'contact', scroll: 5, height: '100vh' } }
            }),
            Thank: lazyLoadComponent({
                componentFactory: () => import('./components/corporate/Thank'),
                loading: SkeletonBox
            }),
            Footer: lazyLoadComponent({
                componentFactory: () => import('./components/corporate/Footer'),
                loading: SkeletonBox,
            }),
        },
        mixins: [Post],
        data() {
            return {
                num: 0,
                page: 'corporate',
                numberposts: 6,
                locale: localStorage.locale || 'vi',
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('scroll', this.navScroll)
                window.addEventListener('scroll', this.scrollBottom)
            })
            document.onreadystatechange = () => {
                if (document.readyState === "complete") {
                    WebFont.load({
                        google: {
                            families: ['Open Sans:400,700&display=swap']
                        }
                    })
                    document.querySelector('html').classList.remove('loading')
                }
            }
        },
        methods: {
            getNum(data) {
                this.num = parseInt(data)
            },
            navScroll: function () {
                let el = document.querySelectorAll('[data-scroll]')
                let totalSelector = el.length
                //console.log(el, totalSelector)
                for (let i = 0; i < totalSelector; i++) {
                    let posTop = el[i].getBoundingClientRect().top,
                        posBottom = el[i].getBoundingClientRect().bottom

                    if (posTop <= 300 && posBottom >= 300) {
                        document.querySelector(`[data-anchor="${i}"]`).classList.add('active')
                        el[i].classList.add('active')
                    } else {
                        document.querySelector(`[data-anchor="${i}"]`).classList.remove('active')
                    }
                }
            },
            scrollBottom() {
                let _scrollHeight = document.documentElement.scrollHeight || document.body.scrollTop;
                let _scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                let _wHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //let _Height = _scrollHeight - _scrollTop - 204;

                if (_scrollHeight < _scrollTop + _wHeight + 100) {
                    document.querySelector('.nav-scroll__social').style.display = 'none';
                    document.querySelector('.scroll-down').style.display = 'none';
                } else {
                    document.querySelector('.nav-scroll__social').style.display = "";
                    document.querySelector('.scroll-down').style.display = "";
                }
            },
            changeLocale(locale) {
                this.$i18n.locale = locale
                this.locale = locale
                this.fetch_news(locale)
                localStorage.locale = this.locale
            }
        },
    }
</script>

<style lang="scss">
    @import './assets/scss/all.scss';
    .corporate-content {
        display: flex;
        width: 100%;

        &__left {
            @include media(set-min-w, 1200) {
                width: calc(100% - 245px);
                /*width: -moz-calc(100% - 270px);*/
                /*width: -webkit-calc(100% - 270px);*/
                z-index: 10;
                /*width: 100vmax;*/
            }
        }
        &__right {
           //width: 20%;

            .menu-open & {
                z-index: 999;
            }
        }
    }
</style>